// scss-docs-start theme-color-variables
$primary:       #005a5a;
// $secondary:     $gray-600;
// $success:       $green;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red ;
// $light:         $gray-100 ;
// $dark:          $gray-900 ;
// scss-docs-end theme-color-variables

//#005a5a

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
//$font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-family-sans-serif:      'Quicksand', sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;


//xa REVISAR
// Color Scheme
$white:    #fff;
$gray-50:  #EDEDED;
$gray-100: #e9e9e9;
$gray-200: #d9d9d9;
$gray-500: #929292;
$gray-700: #827F7F;
$gray-800: #4d4d4d;
$gray-900: #111;
$black:    #000;

$green-1: #D6E5D3;
$green-2: #C5DAC1;
$green-3: #a2b89e;
$green-5: #529A58;
$green-8: #204E44;

$deepsea: #0A5864;
$wine: #403D61;
$jewel: #059919;

$highlight-1: #FBE0E0;
$highlight-2: #864141;

$--swiper-navigation-color: #0A5864
