.module {
  &--bg {
    background-color:#dff7f6;
  }

  &--white {
    background-color: $white;
  }
  &--img {
    //background-image: url('~assets/img/ui/bg-info.png');
    background-repeat: no-repeat;
  }

  &--lightgray {
    background-color: $gray-500;
  }

  &--gray {
    background-color: $gray-200;
  }

  &--halfgreen {
    @include media-breakpoint-down(sm) {
      background-color: $green-5;
    }

    @include media-breakpoint-up(md) {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        content: "";
        background-color: $green-5;
      }
    }
  }
}
