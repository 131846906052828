.typography__headline,
.typography__headline-1,
.typography__headline-2,
.typography__headline-3,
.typography__headline-4,
.typography__headline-5,
.typography__headline-6 {
  position: relative;
  display: inline-block;
  line-height: 1.1;
  font-weight: $font-weight-light;
  color: $primary;
  margin-bottom: 2.5vh;

  @include media-breakpoint-down(sm) {
    line-height: 1.25;
  }

  span {
    display: block;
    font-weight: $font-weight-normal;
  }

  &--green {
    color: $green-8;
  }

  &--white {
    color: $white;

    &::after {
      background-color: $white !important;
    }
  }
}

h1.typography__headline,
h1.typography__headline-1,
h2.typography__headline-1,
h3.typography__headline-1,
h4.typography__headline-1,
h5.typography__headline-1,
h6.typography__headline-1 {
  font-size: 2.75rem;
  margin-bottom: 3vh;
  font-weight: $font-weight-bold;
}

h2.typography__headline,
h1.typography__headline-2,
h2.typography__headline-2,
h3.typography__headline-2,
h4.typography__headline-2,
h5.typography__headline-2,
h6.typography__headline-2 {
  font-size: 1.625rem;

  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 4px;
    background-color: $green-8;

    @include media-breakpoint-up(md) {
      margin-top: 10px;
    }
  }
}

h3.typography__headline,
h1.typography__headline-3,
h2.typography__headline-3,
h3.typography__headline-3,
h4.typography__headline-3,
h5.typography__headline-3,
h6.typography__headline-3 {
  font-size: 1.5rem;

  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 4px;
    background-color: $green-8;

    @include media-breakpoint-up(md) {
      margin-top: 10px;
    }
  }
}

h4.typography__headline,
h1.typography__headline-4,
h2.typography__headline-4,
h3.typography__headline-4,
h4.typography__headline-4,
h5.typography__headline-4,
h6.typography__headline-4 {
  font-size: 1.375rem;
  margin-bottom: 20px;

  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 4px;
    background-color: $green-8;

    @include media-breakpoint-up(md) {
      margin-top: 10px;
    }
  }
}

h5.typography__headline,
h1.typography__headline-5,
h2.typography__headline-5,
h3.typography__headline-5,
h4.typography__headline-5,
h5.typography__headline-5,
h6.typography__headline-5 {
  font-size: 1.25rem;
  font-weight: $font-weight-medium;
}

h6.typography__headline,
h1.typography__headline-6,
h2.typography__headline-6,
h3.typography__headline-6,
h4.typography__headline-6,
h5.typography__headline-6,
h6.typography__headline-6 {
  font-size: 1.125rem;
  font-weight: $font-weight-medium;
}

p {
  line-height: 1.3;

  @include media-breakpoint-up(lg) {
    line-height: 1.5;
  }

  &.typography__leaf {
    font-size: 1.25rem;
  }
}

.module {
  .headline p {
    font-size: 1.15rem;
    color: $primary;
    font-weight: $font-weight-medium;
  }
}
