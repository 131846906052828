/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Flex&display=swap");

@import "styles/main.scss";
@import "~@angular/cdk/overlay-prebuilt.css";

:root {
  --nectar-accent-color: #005a5a;
  --nectar-extra-color-1: #ffcb19;
  --nectar-extra-color-2: #cdf0ef;
  --nectar-extra-color-3: #ffffff;
  --color-letra-empresas: #444444;
  --verde-1: #015b5a;
  --verde-2: #004240;
  --verde-3: #2f6562;
  --blanco-1: #efefef;
  --blanco-2: #cdcdcc;
  --blanco-3: #cdcdcc;
  --fuente: "Quicksand", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Quicksand";
}

body {
  display: flex;
  flex-direction: column;
  border: 4px solid #cdf0ef;
  width: 100%;
  background-color: #cdf0ef;
  // overflow-x: hidden;
}

.filter {
  display: none;
}

.cdk-describedby-message-container.cdk-visually-hidden {
  display: none;
}

.cdk-overlay-container {
  * {
    * {
      background: var(--verde-3);
      transition: all 0.3s ease-in-out;
      color: var(--blanco-1);
      padding: 2px 4px;
      border-radius: 4px;
    }
  }
}
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
