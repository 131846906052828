

.grid {
  position: relative;

  > .mat-card--default,
  > .mat-card--link,
  > .mat-card--member {
    margin: 20px 0;
  }
  
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .mat-card--default,
    > .mat-card--member {
      flex: 0 0 calc(50% - 24px);
      max-width: calc(50% - 24px);
      margin: 12px;
    }
  }

  @include media-breakpoint-up(md) {
    // > .mat-card--default {
    //   flex: 0 0 calc(50% - 24px);
    //   max-width: calc(50% - 24px);
    //   margin: 12px;
    // }

    > .mat-card--member {
      flex: 0 0 calc(33% - 24px);
      max-width: calc(33% - 24px);
    }
  } 
  
  @include media-breakpoint-up(lg) {
    > .mat-card--default,
    > .mat-card--member {
      flex: 0 0 calc(33% - 40px);
      max-width: calc(33% - 40px);
      margin: 20px;
    }
  }   
}
