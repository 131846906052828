.module {
  &__image {  
    margin: 30px auto;

    img {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 220px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__video {
    margin: 30px auto;
  }
}