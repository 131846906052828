// @import '~@angular/material/theming';
// @use "sass:math";

/*Proyect variables Fibras*/
@import "_variables.scss";

// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import 'abstracts/index';
// @import '~bootstrap/scss/mixins';
// @import '~bootstrap/scss/grid';
// @import '~bootstrap/scss/utilities';

// @import 'base/index';
// @import 'vendors/index';
// @import 'material/index';
@import 'elements/grid';
@import 'elements/filter';
@import 'modules/index';
@import 'typography/index';
// @import 'pages/index';
