.person--dialog {
  @media (max-width: 768px) {
    width: 100% !important;
    max-width: 100% !important;
  }

  .modal-dialog {
    position: relative;
    padding: 0;
    overflow: visible;
  }

  .modal-content {
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
      max-height: 100vh;
    }

    @media (min-width: 992px) {
      // height: 640px;
      overflow: hidden;
    }
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 16px;
    width: 40px;
    height: 40px;
    background-color: $primary;
    border-radius: $border-radius;
    color: $white;
    z-index: 2;

    @media (min-width: 992px) {
      top: -50px;
      right: 0;
    }
  }

  // Custom Styles
  // Left Column
  .profile-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $body-bg;
  }

  .footer {
    background-color: $gray-800;
    padding: 30px;
    margin-top: auto;
    color: $white;
  }

  .contents {
    .title {
      font-size: larger;
      font-weight: 500;
    }

    .body {
      font-size: 12px;
    }

    background-color: $gray-100;
    padding: 20px;
    margin-top: auto;
    color: $black;
    margin-bottom: 10px;
  }

  .person {
    padding: 20px;

    &__photo {
      width: calc(100% + 40px);
      margin-top: -20px;
      margin-left: -20px;
      margin-bottom: 20px;
      max-width: 200px;
    }

    &__name {
      display: block;
      margin-bottom: 10px;
      font-size: 1.5rem;
      line-height: 1.3;
      color: $black;

      strong {
        display: block;
      }
    }

    &__rol {
      display: block;
      font-size: 0.75rem;
      line-height: 1.4;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .location {
    font-size: 1rem;
    color: $white;

    // span {
    //   display: block;
    // }
  }

  .details {
    color: $gray-50;
    font-size: 0.85rem;

    span {
      display: block;
    }

    i.fas {
      width: 16px;
      height: 16px;
      font-size: 1rem;
      vertical-align: middle;
      margin-right: 0px;
    }
  }

  // Right Column
  .contact-wrap {
    padding: 20px;

    @media (min-width: 992px) {
      height: 640px;
      overflow: auto;
    }

    ul {
      padding-left: 1rem;
    }
  }
}
