.module {
  position: relative;
  z-index: 1;
  padding-top: 30px;
  padding-bottom: 30px;

  @include media-breakpoint-up(md) {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 8vh;
    padding-bottom: 8vh;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 60px;
    padding-bottom: 50px;
  }  

  blockquote {
    font-size: 1.125rem;
    font-style: italic;
    line-height: 1.4;
  }

  &__header {
    position: relative;

    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
    }
    
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 25px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 15px;
    }
  }

  &__items {
    @include media-breakpoint-down(sm) {
      .mat-card--project {
        display: block;

        +.mat-card--project {
          margin-top: 16px;
        }
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      
      .mat-card--project {
        flex: 0 0 calc(50% - 20px);
        margin: 10px 3px;
      }
    }

    @include media-breakpoint-up(lg) {
      .mat-card--project {
        flex: 1;
      }
    }

    @include media-breakpoint-up(xl) {
      .mat-card--project {
        margin: 10px 5px;
      }
    }
  }
}