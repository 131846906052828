.filter {
  // background-color: $green-8;
  background-color: $green-3;
  padding: 0;

  @include media-breakpoint-up(md) {
    padding: 11px 0;

    &__fields {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(130px, 2fr));
      grid-gap: 16px;
    }
  }
  
  .form {
    .mat-input-element {
      caret-color: $black;
    }

    .ui-icon {
      color: $black !important;
     }    

    .mat-form-field {
      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }
      
      @include media-breakpoint-up(md) {
        font-size: 0.75rem;
      }
      
      // Focus   
      &.mat-focused {
        .mat-form-field-label {
          color: $black;
        }
        
        .mat-select-arrow {
          color: $black;
        }       
      }
    }

    .mat-form-field-appearance-fill {
      .mat-form-field-flex {
        background-color: transparent;
        padding: 0;
        color: $black;
      }

      .mat-select-arrow {
        color: $black;
      }

      .mat-form-field-label {
        color: rgba($black, .8);
      }

      .mat-select-value {
        color: $black;
      }

      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .mat-form-field-underline {
        bottom: 0;

        &::before {
          background-color: rgba($green-8, 0.42);
        }
      }

      .mat-form-field-ripple {
        background-color: rgba($green-8, 0.87);
      }
    }
  }

  // Mobile display filters button
  .mat-expansion-panel {
    background-color: transparent !important;
    border-radius: 0;

    .mat-expansion-panel-header {
      height: 48px !important;
      background-color: $green-8 !important;
      padding-right: 15px;
      padding-left: 15px;
      border-bottom: 1px solid rgba($black, .08);
      
      .mat-expansion-panel-header-title {
        color: $green-2;
        font-size: 0.875rem;
      }

      .mat-expansion-indicator {
        position: relative;
        top: -1px;

        &::after {
          color: $green-2;
          transform-origin: center right;
        }
      }

      @include media-breakpoint-up(md) {
        display: none !important;
      }
    }

    .mat-expansion-panel-body {
      padding: 24px 0 16px;

      @include media-breakpoint-up(md) {
        padding: 0;  
      }
    }    
  }

  .mat-button-base.mat-flat-button {
    font-size: 0.75rem !important;
    min-width: 100px;

    // @include media-breakpoint-down(sm) {
    //   display: block;
    //   width: 100%;
    // }
  }
}
